
<app-no-internet-bar></app-no-internet-bar>

<!-- <div *ngIf="routeLoading" class="loading-box w-full h-full absolute z-5 surface-50 opacity-70">
</div> -->

<p-blockUI [blocked]="showRouteLoading">
  <div class="loading-box"></div>
</p-blockUI>


<div class="device-size__{{ sysService.deviceSize$ | async }}">

  <router-outlet></router-outlet>

</div>

<p-toast position="bottom-center"></p-toast>