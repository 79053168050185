import { Component } from '@angular/core';
import { Modal } from '../modal';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-new-app-version-modal',
  standalone: true,
  imports: [
    TranslateModule,
    ButtonModule,
    ModalHeaderComponent
  ],
  templateUrl: './new-app-version-modal.component.html',
  styleUrl: './new-app-version-modal.component.scss'
})
export class NewAppVersionModalComponent extends Modal {

  onReaload() {
    window.location.reload();
  }

}
